.color-chooser-button {
    /* Span the whole wrapper */
    height: 100%;
    width: 100%;

    // Override button style.
    background: transparent;
    border: 0;
    cursor: pointer;
}

.color-chooser {
    width: 15em;
    display: flex;
    flex-flow: wrap;

    /* Popper does not like margins */
    margin: 0;

    .popup-menu-item {
        width: 1.3em;
        height: 1.3em;
        display: flex;
        margin: 0.2em;
        border: 1px solid black;
        padding: 0;
        border-radius: 0.2em;
        flex-shrink: 0; /* preserve aspect ratio */

        svg {
            margin: auto;
            display: block;
        }
    }
}

@media screen and (width <= 1024px) {
    .color-chooser {
        max-width: 26em;
        width: 100%;

        .popup-menu-item {
            width: 2em;
            height: 2em;
        }
    }
}
